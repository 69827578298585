import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Conversation Configuration`}</h1>
    <ul>
      <li parentName="ul">{`The following `}<a parentName="li" {...{
          "href": "/conversation-configuration",
          "title": "conversation configuration"
        }}>{`conversation configuration`}</a>{` are available from the `}<a parentName="li" {...{
          "href": "/configuration",
          "title": "configuration"
        }}>{`configuration`}</a>{` view of the `}<a parentName="li" {...{
          "href": "/admin-interface",
          "title": "admin interface"
        }}>{`admin interface`}</a>{`:`}
        <ul parentName="li">
          <li parentName="ul">{`Change topic`}</li>
          <li parentName="ul">{`Change description`}</li>
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/seed-comments",
              "title": "seed comments"
            }}>{`seed comments`}</a>{``}</li>
          <li parentName="ul">{`Participants can see the visualization`}</li>
          <li parentName="ul">{`Participants can submit comments`}</li>
          <li parentName="ul">{`Show the two explanation modals above voting and the visualization`}</li>
          <li parentName="ul">{`Prompt participants to subscribe to updates`}
            <ul parentName="li">
              <li parentName="ul">{`A prompt is shown to users once they finish voting on all available comments. They may optionally provide their email address to receive notifications when there are new comments to vote on.`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Show social sharing buttons`}
            <ul parentName="li">
              <li parentName="ul">{`Facebook login prompt`}</li>
              <li parentName="ul">{`Twitter login prompt`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Customize submit button color`}
            <ul parentName="li">
              <li parentName="ul">{`ie., #e63082`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Customize help text background`}
            <ul parentName="li">
              <li parentName="ul">{`ie., #e63082`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Customize help text color`}
            <ul parentName="li">
              <li parentName="ul">{`ie., #e63082`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Strict `}<a parentName="li" {...{
              "href": "/Moderation",
              "title": "👹 Moderation"
            }}>{`👹 Moderation`}</a>{`, no comments shown without moderator approval`}</li>
          <li parentName="ul">{`Require Auth to Comment, `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` cannot submit comments without first connecting either Facebook or Twitter`}</li>
          <li parentName="ul">{`Require Auth to Vote, `}<a parentName="li" {...{
              "href": "/participants",
              "title": "participants"
            }}>{`participants`}</a>{` cannot vote without first connecting either Facebook or Twitter`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      